import { Footer, Page } from '../../components';
import config from '../../config';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import {
    manageDisableScrolling,
    isScrollingDisabled,
} from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import {
    createResourceLocatorString,
    pathByRouteName,
} from '../../util/routes';
import {
    isAnyFilterActive,
    isMainSearchTypeKeywords,
    isOriginInUse,
} from '../../util/search';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import {
    pickSearchParamsOnly,
    validURLParamsForExtendedData,
    validFilterParams,
    createSearchResultSchema,
} from './SearchPage.helpers';
import css from './SearchPage.module.css';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import SortBy from './SortBy/SortBy';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const validUrlQueryParamsFromProps = (props) => {
    const { location, filterConfig } = props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (
    searchParams,
    sortConfig,
    filterConfig,
) => {
    // Single out filters that should disable SortBy when an active
    // keyword search sorts the listings according to relevance.
    // In those cases, sort parameter should be removed.
    const sortingFiltersActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        searchParams,
        filterConfig,
    );
    return sortingFiltersActive
        ? { ...searchParams, [sortConfig.queryParamName]: null }
        : searchParams;
};

export class SearchPageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSearchMapOpenOnMobile: props.tab === 'map',
            isMobileModalOpen: false,
            currentQueryParams: validUrlQueryParamsFromProps(props),
        };

        this.searchMapListingsInProgress = false;

        this.onMapMoveEnd = debounce(
            this.onMapMoveEnd.bind(this),
            SEARCH_WITH_MAP_DEBOUNCE,
        );
        this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
        this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

        // Filter functions
        this.applyFilters = this.applyFilters.bind(this);
        this.cancelFilters = this.cancelFilters.bind(this);
        this.resetAll = this.resetAll.bind(this);
        this.initialValues = this.initialValues.bind(this);
        this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

        // SortBy
        this.handleSortBy = this.handleSortBy.bind(this);
    }

    // Callback to determine if new search is needed
    // when map is moved by user or viewport has changed
    onMapMoveEnd(viewportBoundsChanged, data) {
        const { viewportBounds, viewportCenter } = data;

        const routes = routeConfiguration();
        const searchPagePath = pathByRouteName('SearchPage', routes);
        const currentPath =
            typeof window !== 'undefined' &&
            window.location &&
            window.location.pathname;

        // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
        const isSearchPage = currentPath === searchPagePath;

        // If mapSearch url param is given
        // or original location search is rendered once,
        // we start to react to "mapmoveend" events by generating new searches
        // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
        if (viewportBoundsChanged && isSearchPage) {
            const { history, location, filterConfig } = this.props;

            // parse query parameters, including a custom attribute named category
            const { address, bounds, mapSearch, ...rest } = parse(
                location.search,
                {
                    latlng: ['origin'],
                    latlngBounds: ['bounds'],
                },
            );

            //const viewportMapCenter = SearchMap.getMapCenter(map);
            const originMaybe = isOriginInUse(config)
                ? { origin: viewportCenter }
                : {};

            const searchParams = {
                address,
                ...originMaybe,
                bounds: viewportBounds,
                mapSearch: true,
                ...validFilterParams(rest, filterConfig),
            };

            history.push(
                createResourceLocatorString(
                    'SearchPage',
                    routes,
                    {},
                    searchParams,
                ),
            );
        }
    }

    // Invoked when a modal is opened from a child component,
    // for example when a filter modal is opened in mobile view
    onOpenMobileModal() {
        this.setState({ isMobileModalOpen: true });
    }

    // Invoked when a modal is closed from a child component,
    // for example when a filter modal is opened in mobile view
    onCloseMobileModal() {
        this.setState({ isMobileModalOpen: false });
    }

    // Apply the filters by redirecting to SearchPage with new filters.
    applyFilters() {
        const { history, sortConfig, filterConfig } = this.props;
        const urlQueryParams = validUrlQueryParamsFromProps(this.props);
        const searchParams = {
            ...urlQueryParams,
            ...this.state.currentQueryParams,
        };
        const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            filterConfig,
        );

        history.push(
            createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                search,
            ),
        );
    }

    // Close the filters by clicking cancel, revert to the initial params
    cancelFilters() {
        this.setState({ currentQueryParams: {} });
    }

    // Reset all filter query parameters
    resetAll(e) {
        const { history, filterConfig } = this.props;
        const urlQueryParams = validUrlQueryParamsFromProps(this.props);
        const filterQueryParamNames = filterConfig.map(
            (f) => f.queryParamNames,
        );

        // Reset state
        this.setState({ currentQueryParams: {} });

        // Reset routing params
        // const queryParams = omit(urlQueryParams, filterQueryParamNames);
        history.push(
            createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                {},
            ),
        );
    }

    initialValues(queryParamNames, isLiveEdit) {
        const urlQueryParams = validUrlQueryParamsFromProps(this.props);

        // Query parameters that are in state (user might have not yet clicked "Apply")
        const currentQueryParams = this.state.currentQueryParams;

        // Get initial value for a given parameter from state if its there.
        const getInitialValue = (paramName) => {
            const currentQueryParam = currentQueryParams[paramName];
            const hasQueryParamInState =
                typeof currentQueryParam !== 'undefined';
            return hasQueryParamInState && !isLiveEdit
                ? currentQueryParam
                : urlQueryParams[paramName];
        };

        // Return all the initial values related to given queryParamNames
        // InitialValues for "amenities" filter could be
        // { amenities: "has_any:towel,jacuzzi" }
        const isArray = Array.isArray(queryParamNames);
        return isArray
            ? queryParamNames.reduce((acc, paramName) => {
                  return { ...acc, [paramName]: getInitialValue(paramName) };
              }, {})
            : {};
    }

    getHandleChangedValueFn(useHistoryPush) {
        const { history, sortConfig, filterConfig } = this.props;
        const urlQueryParams = validUrlQueryParamsFromProps(this.props);

        return (updatedURLParams) => {
            const updater = (prevState) => {
                const { address, bounds, keywords } = urlQueryParams;
                const mergedQueryParams = {
                    ...urlQueryParams,
                    ...prevState.currentQueryParams,
                };

                // Address and bounds are handled outside of MainPanel.
                // I.e. TopbarSearchForm && search by moving the map.
                // We should always trust urlQueryParams with those.
                // The same applies to keywords, if the main search type is keyword search.
                const keywordsMaybe = isMainSearchTypeKeywords(config)
                    ? { keywords }
                    : {};
                return {
                    currentQueryParams: {
                        ...mergedQueryParams,
                        ...updatedURLParams,
                        ...keywordsMaybe,
                        address,
                        bounds,
                    },
                };
            };

            const callback = () => {
                if (useHistoryPush) {
                    const searchParams = this.state.currentQueryParams;
                    const search = cleanSearchFromConflictingParams(
                        searchParams,
                        sortConfig,
                        filterConfig,
                    );
                    history.push(
                        createResourceLocatorString(
                            'SearchPage',
                            routeConfiguration(),
                            {},
                            search,
                        ),
                    );
                }
            };

            this.setState(updater, callback);
        };
    }

    handleSortBy(urlParam, values) {
        const { history } = this.props;
        const urlQueryParams = validUrlQueryParamsFromProps(this.props);

        const queryParams = values
            ? { ...urlQueryParams, [urlParam]: values }
            : omit(urlQueryParams, urlParam);

        history.push(
            createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                queryParams,
            ),
        );
    }

    // Reset all filter query parameters
    handleResetAll(e) {
        this.resetAll(e);

        // blur event target if event is passed
        if (e && e.currentTarget) {
            e.currentTarget.blur();
        }
    }

    render() {
        const {
            intl,
            listings,
            filterConfig,
            sortConfig,
            location,
            onManageDisableScrolling,
            pagination,
            scrollingDisabled,
            searchInProgress,
            searchListingsError,
            searchParams,
        } = this.props;
        // eslint-disable-next-line no-unused-vars
        const { mapSearch, page, ...searchInURL } = parse(location.search, {
            latlng: ['origin'],
            latlngBounds: ['bounds'],
        });

        // urlQueryParams doesn't contain page specific url params
        // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
        const urlQueryParams = pickSearchParamsOnly(
            searchInURL,
            filterConfig,
            sortConfig,
        );

        // Page transition might initially use values from previous search
        const urlQueryString = stringify(urlQueryParams);
        const paramsQueryString = stringify(
            pickSearchParamsOnly(searchParams, filterConfig, sortConfig),
        );
        const searchParamsAreInSync = urlQueryString === paramsQueryString;

        const validQueryParams = validURLParamsForExtendedData(
            searchInURL,
            filterConfig,
        );

        const isKeywordSearch = isMainSearchTypeKeywords(config);
        const availableFilters = isKeywordSearch
            ? filterConfig.filter((f) => f.type !== 'KeywordFilter')
            : filterConfig;

        // Selected aka active filters
        const selectedFilters = validFilterParams(
            validQueryParams,
            filterConfig,
        );
        const keysOfSelectedFilters = Object.keys(selectedFilters);
        const selectedFiltersCountForMobile = isKeywordSearch
            ? keysOfSelectedFilters.filter((f) => f !== 'keywords').length
            : keysOfSelectedFilters.length;

        const hasPaginationInfo = !!pagination && pagination.totalItems != null;
        const totalItems =
            searchParamsAreInSync && hasPaginationInfo
                ? pagination.totalItems
                : 0;
        const listingsAreLoaded =
            !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

        const sortBy = (mode) => {
            const conflictingFilterActive = isAnyFilterActive(
                sortConfig.conflictingFilters,
                validQueryParams,
                filterConfig,
            );

            const mobileClassesMaybe =
                mode === 'mobile'
                    ? {
                          rootClassName: css.sortBy,
                          menuLabelRootClassName: css.sortByMenuLabel,
                      }
                    : { className: css.sortByDesktop };
            return sortConfig.active ? (
                <SortBy
                    {...mobileClassesMaybe}
                    sort={validQueryParams[sortConfig.queryParamName]}
                    isConflictingFilterActive={!!conflictingFilterActive}
                    hasConflictingFilters={
                        !!(sortConfig.conflictingFilters?.length > 0)
                    }
                    selectedFilters={selectedFilters}
                    onSelect={this.handleSortBy}
                    showAsPopup
                    contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                />
            ) : null;
        };

        const onMapIconClick = () => {
            this.useLocationSearchBounds = true;
            this.setState({ isSearchMapOpenOnMobile: true });
        };

        const { title, description, schema } = createSearchResultSchema(
            listings,
            searchInURL || {},
            intl,
        );

        const hasNoResult = listingsAreLoaded && totalItems === 0;
        const hasSearchParams = location.search?.length > 0;
        const noResultsInfo = hasNoResult ? (
            <div className={css.noSearchResults}>
                <FormattedMessage id="SearchPage.noResults" />
                <br />
                {hasSearchParams ? (
                    <button
                        className={css.resetAllFiltersButton}
                        onClick={(e) => this.resetAll(e)}>
                        <FormattedMessage id={'SearchPage.resetAllFilters'} />
                    </button>
                ) : null}
            </div>
        ) : null;

        // Set topbar class based on if a modal is open in
        // a child component
        const topbarClasses = this.state.isMobileModalOpen
            ? classNames(css.topbarBehindModal, css.topbar)
            : css.topbar;

        // N.B. openMobileMap button is sticky.
        // For some reason, stickyness doesn't work on Safari, if the element is <button>
        return (
            <Page
                scrollingDisabled={scrollingDisabled}
                description={description}
                title={title}
                schema={schema}>
                <TopbarContainer
                    className={topbarClasses}
                    currentPage="SearchPage"
                    currentSearchParams={urlQueryParams}
                />
                <div className={css.layoutWrapperContainer}>
                    <aside className={css.layoutWrapperFilterColumn}>
                        <div className={css.filterColumnContent}>
                            {availableFilters.map((config) => {
                                return (
                                    <FilterComponent
                                        key={`SearchFiltersMobile.${config.id}`}
                                        idPrefix="SearchFiltersMobile"
                                        className={css.filter}
                                        filterConfig={config}
                                        urlQueryParams={urlQueryParams}
                                        initialValues={this.initialValues}
                                        getHandleChangedValueFn={
                                            this.getHandleChangedValueFn
                                        }
                                        liveEdit
                                        showAsPopup={false}
                                        isDesktop
                                    />
                                );
                            })}
                            <button
                                className={css.resetAllButton}
                                onClick={(e) => this.handleResetAll(e)}>
                                <FormattedMessage
                                    id={'SearchFiltersMobile.resetAll'}
                                />
                            </button>
                        </div>
                    </aside>

                    <div className={css.layoutWrapperMain} role="main">
                        <div className={css.searchResultContainer}>
                            <SearchFiltersMobile
                                className={css.searchFiltersMobileList}
                                urlQueryParams={validQueryParams}
                                sortByComponent={sortBy('mobile')}
                                listingsAreLoaded={listingsAreLoaded}
                                resultsCount={totalItems}
                                searchInProgress={searchInProgress}
                                searchListingsError={searchListingsError}
                                showAsModalMaxWidth={MODAL_BREAKPOINT}
                                onMapIconClick={onMapIconClick}
                                onManageDisableScrolling={
                                    onManageDisableScrolling
                                }
                                onOpenModal={this.onOpenMobileModal}
                                onCloseModal={this.onCloseMobileModal}
                                resetAll={this.resetAll}
                                selectedFiltersCount={
                                    selectedFiltersCountForMobile
                                }
                                isMapVariant={false}
                                noResultsInfo={noResultsInfo}>
                                {availableFilters.map((config) => {
                                    return (
                                        <FilterComponent
                                            key={`SearchFiltersMobile.${config.id}`}
                                            idPrefix="SearchFiltersMobile"
                                            filterConfig={config}
                                            urlQueryParams={validQueryParams}
                                            initialValues={this.initialValues}
                                            getHandleChangedValueFn={
                                                this.getHandleChangedValueFn
                                            }
                                            liveEdit
                                            showAsPopup={false}
                                        />
                                    );
                                })}
                            </SearchFiltersMobile>
                            <MainPanelHeader
                                className={css.mainPanel}
                                sortByComponent={sortBy('desktop')}
                                listingsAreLoaded={listingsAreLoaded}
                                resultsCount={totalItems}
                                searchInProgress={searchInProgress}
                                searchListingsError={searchListingsError}
                                noResultsInfo={noResultsInfo}
                            />
                            <div
                                className={classNames(css.listings, {
                                    [css.newSearchInProgress]:
                                        !listingsAreLoaded,
                                })}>
                                {searchListingsError ? (
                                    <h2 className={css.error}>
                                        <FormattedMessage id="SearchPage.searchError" />
                                    </h2>
                                ) : null}
                                <SearchResultsPanel
                                    className={css.searchListingsPanel}
                                    listings={listings}
                                    pagination={
                                        listingsAreLoaded ? pagination : null
                                    }
                                    search={parse(location.search)}
                                    isMapVariant={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Page>
        );
    }
}

SearchPageComponent.defaultProps = {
    listings: [],
    pagination: null,
    searchListingsError: null,
    searchParams: {},
    tab: 'listings',
    filterConfig: config.custom.filters,
    sortConfig: config.custom.sortConfig,
};

SearchPageComponent.propTypes = {
    listings: array,
    mapListings: array,
    onManageDisableScrolling: func.isRequired,
    pagination: propTypes.pagination,
    scrollingDisabled: bool.isRequired,
    searchInProgress: bool.isRequired,
    searchListingsError: propTypes.error,
    searchParams: object,
    tab: oneOf(['filters', 'listings', 'map']).isRequired,
    filterConfig: propTypes.filterConfig,
    sortConfig: propTypes.sortConfig,

    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string.isRequired,
    }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
    const {
        currentPageResultIds,
        pagination,
        searchInProgress,
        searchListingsError,
        searchParams,
    } = state.SearchPage;
    const pageListings = getListingsById(state, currentPageResultIds);

    return {
        listings: pageListings,
        pagination,
        scrollingDisabled: isScrollingDisabled(state),
        searchInProgress,
        searchListingsError,
        searchParams,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(SearchPageComponent);

export default SearchPage;
